[class^="icon-"]:before,
[class*=" icon-"]:before {
	font-family: FontAwesome;
	font-weight: normal;
	font-style: normal;
	display: inline-block;
	text-decoration: inherit;
}

blockquote {
	padding: 10px 20px;
	margin: 0 0 20px;
	font-size: 17.5px;
	border-left: 5px solid #eee;
	color: $light-grey;

}

header {
	margin:0 0 10px 0;
	padding:15px 0 5px 0;
	background-color: $grey;
	position: relative;
	width: 100%;
	z-index: 100;
}


img {
	max-width:100%;
	height:auto;
}
hr {

	border-top:1px solid $body;
}

ul {
	padding-left:20px;
	margin:0 0 10px;
	li {
		margin-bottom: 5px;
	}
}

.btn {

	letter-spacing: 0.1em;

	text-transform:uppercase;
	font-size: 0.85em;
	line-height: 1.3em;
	border: 1px solid $light-grey;
	background: none;
	color: $grey;
}
.btn:hover, .btn:focus {
	color: darken($grey, 15%);
	border-color: darken($light-grey, 15%);
	
}
.btn:focus {
	outline: none;
}
.btn-lg {
	font-size: 1em;
}

.btn-reverse {
	color:$white;
	background:none;
	border-color: $white;
	margin-bottom: 10px;
	background:#00a1f5;
	opacity:0.9;
	/*background-color:#006fab;*/
}
.btn-reverse:hover,.btn-.btn-reverse:focus,.btn-.btn-reverse:active {
	color:$white;
	background-color:$trans-black2;
	border-color: $white;
}
.menu-toggle {
	margin-bottom: 0;
}

.form-control {
	color: $white;
	
}


.social {
	margin-bottom: 20px;
	a {
		color: $white;
		font-size: 2em;
		margin: 0 3px;
		padding: 3px 5px;
		display: inline-block;
		-webkit-transition: all .2s ease-in-out;
		-moz-transition: all .2s ease-in-out;
		-ms-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
	}
	a:hover {
		-moz-transform: translateY(-5px);
		-webkit-transform: translateY(-5px);
		-o-transform: translateY(-5px);
		-ms-transform: translateY(-5px);
		transform: translateY(-5px);
	}
}
.icon-search--toggle {
	position: fixed;
	top:10px;
	right:10px;
	width:40px;
	height:40px;
	padding:10px;
	text-align: center;
	background:$black;
	color:$white;
	-webkit-border-radius: 30px;
	border-radius: 30px;
	z-index: 1000;
	
	
}
.icon-search--toggle:before {
	content:'\f002';
}
.icon-search--toggle.closer:before {

	content: '\f00d';
}
.search__icon {
	position: absolute;
	top: 18px;
	left: 17px;
	color: $white;
}
.search__container {
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	z-index: 10;
	height:20px;
	
	.search__inside {
		display: none;
		padding: 10px 55px 10px 10px;
		background: $black;
		.form-group {
			margin:0;
		}
	}

}


.navbar-collapse {
	background: darken($brand-primary, 15%);;
	padding:15px;
	margin: -15px;
}
.navbar-collapse.in {
	overflow: visible;
}

.navbar-toggle {
	position: absolute;
	color: $white;
	top:80px;
	right:10px;
	z-index: 1000;
	margin: 0;
	background-color: $body;
	.icon-bar {
		background: $grey;
	}
}




.news__container {
	margin: 50px 0 20px 0;
	
	.news-item {
		background: $white;
		margin-bottom: 20px;
		padding:15px;
	}
}


#shows {
	

	
	
	
}

.show__container {

	width:100%;
	height:auto;
	float:none;
	display:block;
	clear:both;
	position:relative;
	min-height:1px;
	padding-left:2.5px;
	padding-right:2.5px;
	z-index:1;
	border-bottom: 1px solid $tint1;
	margin-bottom:5px;
	.show__inside {
		position: relative;
		.show__image-container {
			width:35%;
			display: inline-block;
			*display: inline;
			_height:100px;
			zoom:1;
			margin:0 3% 5px 0;
			vertical-align: top;
		}
		.show__date-container {
			font-size: 0.8em;
			color:$black;

		}

		.show__info {
			display: inline-block;
			*display: inline;
			_height:100px;
			zoom:1;
			width:60%;
			vertical-align: top;
			
			
			.show__title-container {
				position:static;
				margin: 0 0 55px 0;
				text-align: left;
				h3 {
					margin:0;
					font-size: 1.1em;
				}
			}
			.show__overlay {
				position:absolute;
				text-align: right;
				bottom: 5px;
				right: 5px;
				width:190px;
				.btn {
					margin:0;
					margin-left: 6px;
					font-size: 1.5em;
					display: inline-block;
					width: auto;
					padding:10px 15px;
					span {
						display: none;
					}
				}
				.preview {
					position: relative;
					width:45px;
					text-align: center;
					font-size: 3.5em;
					cursor: pointer;
					padding: 0;
					z-index: 100;
					-webkit-transition: all .3s ease;
					-moz-transition: all .3s ease;
					-ms-transition: all .3s ease;
					-o-transition: all .3s ease;
					transition: all .3s ease;
					background: none;
					vertical-align: middle;
					display: inline-block;
					color: $white;
				}
			}
		}
	}
}

#sliders {
	margin: 0px 0 20px 0;

}

#sliders h2{
    margin: 0 auto;
	position: relative;
    width: 100%;
}

.main-slider-container {
	position: relative;
	min-height: 1px;
	padding-left: 25px;
	padding-right: 25px;
	max-width: 750px;
}

.main-slider {
	
}

#sliders {
	@media (min-width:769px) {
		padding-top:65px;
	}
	@media (max-width:768px) {
		padding-top:115px;
	}	
	@include breakpoint(xs) {
		padding-top:0px;
	}
}

#secondarySlider > div > div > div.slide__show__container.ng-scope.slick-slide.slick-current.slick-active > div > div.slide__show__info > div
{
	@media (min-width:768px) {
		height: 100px !important
	}	
}

.slide__show__inside {
	position: relative;
	height: 100%;
	padding-top:0 !important;

	.slide__show__image-container {
		width:100%;
		display: inline-block;
		zoom:1;
		margin:0;
		vertical-align: top;
		@include breakpoint(sm) {
			overflow: hidden;
		}
		
		img {
			width: 100%;
		}
	}
	
	.slide__show__title-container {
		position: relative;
		padding: 15px 20px;
		min-height:105px;
		height: auto !important;
		height: 60px;
		width:100%;
		float:none;
		margin:0;
		background-color:#006FAB;
		text-align: center;

		@include breakpoint(sm) {
			text-align: center;
		}
		@include breakpoint(md) {
			bottom: 0;
			left: 0;
			width: 100%;
			min-height: 80px;
			text-align: left;
			padding: 20px;
		}
		@include breakpoint(lg) {
			min-height: 85px;
			
		}

		font {
			color:white !important;
		}
		
		h3 {
			margin:0;
			font-size: 1.25em;
			text-transform: none;
			position:relative;
			margin:0;
			color:$white;
			margin-bottom: 10px;
			@include breakpoint(md) {
				margin-bottom: 0;
				.main-slider & {
					display: inline-block;
					float: left;
				}
			}
			@include breakpoint(lg) {
				
				font-size: 1.4em;
				
			}
			
			a {
				color: $white;
				&:hover, &:focus {
					text-decoration: none;
				}
			}

			
				
					
				
			
		}
		p {
			color: $white;
			font-weight: 400;
			margin-bottom: 0;
			@include breakpoint(md) {
				display: inline-block;
				float: right;
				text-align: right;
				width: 45%;
			}
			
		}
		
		.btn {
			margin:0;
			margin-left: 5px;
			font-size: 1.5em;
			display: inline-block;
			width: auto;
			padding:10px 15px;
			span {
				display: none;
				@include breakpoint(sm) {
					display: inline;
				}
			}
			@include breakpoint(sm) {

				font-size: 0.8em;
				display: inline-block;
				vertical-align: middle;
				padding: 10px;
				margin-left: 0;

			}
			@include breakpoint(lg) {
				font-size: 1em;
			}
		}
		.preview {
			position: relative;
			width:40px;
			text-align: center;
			font-size: 3.5em;
			line-height: 1;
			cursor: pointer;
			padding: 0;
			z-index: 100;
			-webkit-transition: all .3s ease;
			-moz-transition: all .3s ease;
			-ms-transition: all .3s ease;
			-o-transition: all .3s ease;
			transition: all .3s ease;
			background: none;
			vertical-align: middle;
			display: inline-block;
			color: $white;
			@include breakpoint(sm) {
				width: 40px;
				height: 40px;
				font-size: 3em;
				color: $white;
				margin-bottom: 0;
				line-height: 1;
				    position: absolute;
			    top: -50px;
			    right: 5px;
			}
		}
	}
}

.fallback-square {
	@include breakpoint(sm) {
		margin-top: 56px;
	}
	img {
		margin: 0 auto;
	}
	
}

.modal-content {
	border: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.detail__modal {
	.modal-body {

		.detail__top {
			margin-bottom: 20px;
		}
	}

}

.modal-footer {
	text-align: center;
}






.video-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
}
.video-container iframe,  
.video-container object,  
.video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding-top: 35px;
}


.video-swap-container {
	position: relative;
}

.play-button {
	position: absolute;
	padding:8px 12px;
	background: $brand-primary;
	color: $white;
	bottom:5px;
	left:5px;
	cursor: pointer;
	text-transform: uppercase;
	vertical-align: middle;
	.fa {
		font-size: 2.5em;
		vertical-align: middle;
	}
	span {
		vertical-align: middle;
	}
}
.play-button:hover, .play-button:focus {
	background: darken($brand-primary, 15%);
	
}

.main-content {
	
	line-height: 185%;

	margin: 50px 0;

	ol {
		padding-left: 20px;
	}
}

.booking, .subscribe-enews, .member {
	margin-top: 40px;
}

.iframe-container {
  height: 100%;
  min-height: 1200px;
  margin-bottom: 40px; }
  .iframe-container > iframe {
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    min-height: 1200px; }

iframe#myIframe {
  border: none;
  outline: none;
  width: 100%;
  height: 100%; }

iframe#myIframe,  {
	border:none;
	outline: none;
	width:100%;
	height:500px;
}

.sponsors {
	background: $white;
	text-align:center;
	padding:30px 0;
	clear: both;
	.sponsors__logos {
		text-align: center;
		a {
			margin:10px;
			display: inline-block;
		}
	}
}
footer {
	background-color: $light-grey;
	color: $white;
}
#footer {
	
	@include breakpoint(xs) {
		font-size:0.8em;
		.col-md-4 {
			margin-top:50px;
		}
	}

	padding:30px 0 60px 0;
	a {
		color:#ffffff;
		text-decoration: none;
	}
	a:hover {
		text-decoration: underline;
	}
	.btn {
		font-size: 1em;
	}
	.btn:hover, .btn:focus {
		text-decoration: none;
	}
	.footer-links {
		list-style: none;

		font-size:0.95em;

		li {
			display: block;
		}
	}
	small {
		color: $tint1;
	}
}


.scroll-top {
	position: fixed;
	right: 2%;
	bottom: 2%;
	width: 41px;
	height: 41px;
	z-index: 1049;
	text-align: center;
	.btn {
		font-size: 1.6em;
		width: 41px;
		height: 41px;
		border-radius: 100%;
		line-height: 22px;
		text-align: center;
		padding: 8px 0;
		&:focus {
			outline: none;
		}
	}
}

@media (max-width: 768px) { 
	.carousel {
		margin:0 -15px 60px -15px;
	}
}

@media (min-width:768px) {
	header {
		padding:15px 0 0 0;
		position: fixed;
		top:0;
		left:0;
		right:0;
		width:100%;
		z-index: 101;
		padding: 10px 0;
	}
	.btn-reverse {
		margin-bottom: 0;
	}

	.dropdown {
		display: inline-block;
	}
	.dropdown-menu {
		margin:0;
		border:none;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	
	.booking, .subscribe-enews, .member {
		margin-top: 160px;
	}
	
	


	.icon-search--toggle {
		display: none;
	}
	.search__icon {
		position: absolute;
		top: 10px;
		left: 10px;
		color: $grey;
		width:20px;
		height:20px;
	}
	.search__toggle {
		display: none;
		
	}
	.search__container {
		position: static;
		min-height: 1px;
		height: auto !important;
		height:30px;
		.search__inside {
			display: block !important;
			position: relative;
			min-height: 1px;
			height: auto !important;
			height:30px;
			padding:0;
			-webkit-box-shadow: none;
			box-shadow: none;
			background: $body;

		}
	}
	
	.subscribe {
		display: block;
		text-align: right;
	}
	
	
	.navbar-collapse {
		background: none;
		padding:0;
		margin: 0;
	}



	
	.news__container {
		margin: 180px 0 0 0;
	}	
	
	#shows {
		text-align: center;
		padding: 0 0 30px 0;
	}
	.show__container {
		width:236px;
		height:236px;
		display: inline-block;
		position:relative;
		min-height:1px;
		padding-left:2.5px;
		padding-right:2.5px;
		z-index:1;
		clear:none;
		border-bottom:none;
		.show__inside {

			.show__image-container {
				width:100%;
				float:none;
				margin:0;
			}
			.show__info {
				display:block;
				position:absolute;
				top:0;
				left:0;
				width: 100%;
				height: 100%;
				
				.show__date-container {
					position: absolute;
					top: 276px;
					left: 0;
					padding: 5px 15px;
					background-color:#0f70b7;
					color:$white;
					font-size: 1em;
					font-weight: 400;
					width:100%;
					text-align:centre;
				}
				.show__title-container {
				    text-align: center;
					position:absolute;
					bottom:-104px;
					left:0;
					right:0;
					padding:10px 15px;
					min-height:80px;
					height: auto !important;
					height: 60px;
					width:100%;
					float:none;
					margin:0;
					background-color:$white;
					cursor: pointer;
					h3 {
						position:relative;
						margin:0;
						color:$black;
						font-size: 1.2em;
						font-weight:525;
					}
				}
				.show__overlay {
					position:absolute;
					width:100%;
					height:100%;
					filter:alpha(opacity=0);
					-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
					opacity:0;
					transition:all ease 0.5s;
					-webkit-transition:all ease 0.5s;
					-moz-transition:all ease 0.5s;
					top:0;
					left:0;
					right:0;
					z-index:10;
					padding:20px 25px 15px 25px;
					background-color: $trans-black2;
					text-align: center;
					&:hover {
						filter:alpha(opacity=100);
						-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
						opacity:1;
					}
					.btn {
						font-size: 1em;
						margin-bottom: 10px;
						margin-left: 0;
						display: block;
						width: 100%;
						padding: 14px 15px;
						span {
							display: inline;
						}
					}
					.preview {

						width: 54px;
						height: 54px;
						font-size: 4em;
						color: $white;
						margin: 0 auto 10px auto;
						line-height: 1;

					}
				}
			}
		}
	}
	
	

	.touch {
		
		.show__container {
			height:auto;
			vertical-align: top;
			.show__inside {
				
				.show__image-container {

					
				}
				.show__info {
					position:static;
					margin-bottom:5px;

					.show__title-container {
						max-height: 110px;
						min-height: 110px;
						overflow: hidden;
						position:static;
						background:$white;
						text-align: center;
						h3 {
							color:$black;
						}
						padding-top: 30px;
					}
					.show__date-container {
						text-align:centre;
						position: relative;
						top: 0;
					}
					.show__overlay {
						position:static;
						filter:alpha(opacity=1);
						-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
						opacity:1;
						background:$white;
						padding:0 10px 10px;
						&:hover {
							filter:alpha(opacity=100);
							-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
							opacity:1;
						}
						.btn {
							margin:0;
							margin-bottom:5px;
							margin-left: 0;
						}
						.preview {
							color: $brand-primary;
						}
					}
				}
			}
			
		}

	}
	
	
	.main-content {
		margin: 150px 0 80px 0;
	}	

	#footer {	
		.footer-links {
			list-style: none;
			margin: 0;
			li {
				display: inline-block;
				margin: 0 15px;
				a {
					padding:0;
				}
				a:hover, a:focus {
					text-decoration: underline;
					background: none !important;
				}
			}
		}
	}
	
	
	.carousel {
		
		margin: 0 5px 60px 5px;
	}
	
	
	
}



@media (min-width:992px) {
	.booking, .subscribe-enews, .member {
		margin-top: 100px;
	}
	.news__container {
		margin: 100px 0 0 0;
	}	
	
	.modal-body {
		width: 700px;
		margin: 0 auto;
		.detail__top {
			margin-bottom: 30px;
		}
	}

	#shows {
		
	}
	.show__container {
		width:231px;
		height:231px;
	}
	.touch {
		.show__container {
			height:auto;
			
		}
		
	}
	
	.carousel {

		margin: 0 10px 60px 10px;
	}

	.main-content {
		margin: 100px 0;
	}
	

	
}
@media (min-width:1200px) {
	
	
	#shows {
		
	}
	.show__container {
		width:281px;
		height:389px;
	}
	
	.preview {
		
	}
	
	
}


#external-shows {
	overflow: hidden;
}
//Slick custom styles

.slick-slide {
	
	.external-show {
		background: $white;
		display: block;
		margin:0 2px;
		img {
			margin: 0 auto;
			max-width: 100%;
			width: 100%;
		}
	}
}

.slick-prev, .slick-next {
	width: 36px;
	height: 36px;
	margin-top: -18px;
	margin-left:34px;
	margin-right:34px;
	opacity: 0;

	top: 50%;
	z-index: 100;
	transition: opacity .1s ease;
	.slick-slider:hover & {
		opacity: 1;
	}
}
.slick-prev:before, .slick-next:before {
	font-size: 30px;
	color:white !important;
}


.slick-prev {
	left: -34px;
	
}
.slick-next {
	right: -34px;
	
}

.slick-dots {
	bottom: -30px;
	padding: 0 30px 0 0
}

.mobile-header {
	display: block;
	text-align: center;
	padding: 10px 20px;
	margin: 20px 0 10px;
	background: darken($body, 5%);
	color: darken($brand-primary, 10%);
	
	@include breakpoint(sm) {
		display: none;
	}
	&.ntix-header {
		background: $ntix-orange;
		color: $white;
		padding: 3px 20px;
		@include breakpoint(sm) {
			display: block;
			margin: 0;
			position: absolute;
			top:0;
			left:0;
			right:0;
			margin: 0 14px;
			z-index: 10;
		}
	}
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    background-color: darken($brand-primary, 10%);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: white;
    display: block;
    transition: 0.3s
}

.sidenav a:hover, .offcanvas a:focus{
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.back-to-top {
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    display:none;
    z-index:9999;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

#external-shows {
   .sidenav {padding-top: 15px;}
   .sidenav a {font-size: 18px;}
 }

// this sets the heights for both sliders
// on the yourcentre version of the site
// ntix heights are set in index.html <styles>
// tag
#mainSlider {
 	#secondaryImage {
 	 	@include breakpoint(sm) {
			height: 214px;
	 	}
	 	@include breakpoint(md) {
			height: 349px;
	 	}
	 	@include breakpoint(lg) {
			height: 380px;
	 	}
	}
	#secondaryImageContainer {
 	 	@include breakpoint(sm) {
			max-height: 166px;
	 	}
	 	@include breakpoint(md) {
			max-height: 309px;
	 	}
	 	@include breakpoint(lg) {
			max-height: 297px;
	 	}
	}

	#imageContainer {
 	 	@include breakpoint(sm) {
			max-height: 224px;
	 	}
	 	@include breakpoint(md) {
			max-height: 309px;
	 	}
	 	@include breakpoint(lg) {
			max-height: 353px;
	 	}
	}

}

.show__inside
{
	@include breakpoint(sm) {
		background:white;
 	}
 	@include breakpoint(md) {
		background:none;
 	}
}

// aligning the sliders to the grid
#shows > div > div
{
	margin-left: -16px
}

.row {
	margin-left:0px;
	margin-right:0px;
}
